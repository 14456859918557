import React from "react"
import title_img from "../../assets/title_tmp.png"
import styles from "./cover.module.css"

export default () => (
  <div>
    <div className={styles.img_wrapper}>
      <img
        className={styles.title_img}
        src={title_img}
        alt="the weight of love"
      />
    </div>
    <p className={styles.author}>SUNNA BOHLEN</p>
  </div>
)
