import React from "react"
import styles from "./poem.module.css"

export default () => (
  <>
    <h2 className={styles.chapter_title}>
      Chapter 1:
      <br />
      Sprout
    </h2>
    <div className={styles.poem}>
      <p>
        Diminutia,
        <br />
        every life is a rarity
        <br />
        producing
        <br />a unique episode
        <br />
        by virtue of growth.
      </p>
      <p>
        A sprout
        <br />
        has no concern,
        <br />
        no vision,
        <br />
        but is wise enough to see
        <br />a cruel reality.
      </p>
      <p>
        Vulnerable
        <br />
        as it seems,
        <br />a hopeful dream
        <br />
        is a sign of life.
        <br />
        Sprout shoots up, arouses awe.
      </p>
    </div>
  </>
)
