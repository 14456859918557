import React from "react"
import styles from "./divider.module.css"

export default () => (
  <div className={styles.wrapper}>
    <div className={styles.left_circle} />
    <div className={styles.bar} />
    <div className={styles.right_circle} />
  </div>
)
