import React from "react"
import styles from "./amazonButton.module.css"

// sample https://lesen.amazon.de/kp/embed?asin=B07SC3F83C&preview=newtab&linkCode=kpe&ref_=cm_sw_r_kb_dp_lfo9CbFHP70AM

export default () => (
  <div className={styles.wrapper}>
    <a
      className={styles.button}
      href="https://www.amazon.com/dp/B07SC3F83C/ref=cm_sw_em_r_mt_dp_U_lfo9CbFHP70AM"
    >
      Amazon
    </a>
  </div>
)
