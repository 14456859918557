import React from "react"
import { Link } from "gatsby"
import Helmet from "../components/helmet/helmet"
import Indicator from "../components/indicator/indicator"
import Cover from "../sections/cover/cover"
import Foreword from "../sections/foreword/foreword"
import Poem from "../sections/poem/poem"
import Sentences from "../sections/sentences/sentences"
import AmazonButton from "../components/amazonButton/amazonButton"
import Divider from "../components/divider/divider"
import Footer from "../sections/footer/footer"
// import FloatingMenu from "../components/floatingMenu"

export default () => (
  <div className="container">
    <Helmet subtitle="sample chapter" />
    <Indicator />
    <Cover />
    <Foreword />
    <Poem />
    <Sentences />
    <AmazonButton />
    <p>
      <Link to="/">&lt; Back</Link>
    </p>
    <Divider />
    <Footer />
    {/* <FloatingMenu /> */}
  </div>
)
