import React from "react"
import { Link } from "gatsby"
import WEB_ICON from "../../assets/web.svg"
import styles from "./footer.module.css"

const SNS_ITEMS = [
  {
    icon: WEB_ICON,
    href: "https://sunnabohlen.com",
    alt: "web",
  },
]

export default ({ isInMenu = false }) => (
  <div>
    <div className={`container ${styles.container}`}>
      <ul className={styles.sns_links}>
        {SNS_ITEMS.map((s, i) => (
          <li
            key={`sns-${i}`}
            className={isInMenu ? styles.sns_item_in_menu : styles.sns_item}
          >
            <a href={s.href}>
              <img src={s.icon} alt={s.alt} />
            </a>
          </li>
        ))}
      </ul>
      <div className={styles.impressum}>
        <Link
          className={
            isInMenu ? styles.impressum_link_in_menu : styles.impressum_link
          }
          to="/impressum/"
        >
          IMPRESSUM
        </Link>
      </div>
    </div>
  </div>
)
