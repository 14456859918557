import React from "react"
import styles from "./sentences.module.css"

export default () => (
  <div className={styles.sentences}>
    <p className={styles.para}>
      For me, Berlin has always been a place of healing. A place to overcome
      divisions, break down walls. Here, far from the spirits that trouble the
      sea, I paint my life onto canvas and hang it on the walls of my apartment.
      At night, my paintings reflect against the window glass as if they’re
      floating in the air outside. They adorn the sandstone walls of the old
      church across the square. Berlin is the perfect spiritual haven for anyone
      who doesn’t believe in spirits.
    </p>
    <p className={styles.para}>
      I paint my internal landscape. I paint raw emotion, but somehow the
      finished canvases are calming. I don’t paint every day. It all depends on
      intensity. I gather my experience, my feeling, my need, until it all
      erupts without warning. Then I sit in my studio for hours, even days,
      sometimes without food, without speaking. It’s a shamanic experience. My
      art is my mother’s shadow.
    </p>
    <p className={styles.para}>
      The poetry comes differently. I lift it out of my intellectual life. It
      rises from events, relationships, nodes I can pinpoint. It is the result
      of long spools of thought, my own philosophical thread. My principle of
      resilience. An attitude to life I have reformulated and rebuilt over and
      over again. The words on the page anchor me, even as the paintings float
      free in the night.
    </p>
    <p className={styles.para}>
      I have always loved to be deep within myself, discovring. Early on, I
      learned to like my own company best. But it has taken a lifetime to learn
      to cultivate solitude. To nurture it and let its roots grow deep, so that
      it endures the desiccation of drought and the force of flood. In the end,
      everything feels better when I’m alone. As I am now. Again, or at last.
      Perhaps not for long.
    </p>
    <p className={styles.para}>
      I’ve spent my life giving myself away. In the process, I’ve learned how to
      nurture myself as a foundation for loving others. The lessons of love have
      been painful, and beautiful. The weight of it all grounds me even as new
      love buoys me up.
    </p>
    <p className={styles.para}>
      In Berlin, I’m finally free of the anxiety the ocean has always called up
      in me. I feel safe. The sea is the most powerful force in nature. For me
      it has been a wellspring of trauma and beauty both. Like every experience
      in life. Someday, I’ll return to the sea.
    </p>
    {/* decoration */}
    <p className={styles.para}>
      I was born the morning the Korean War began. Or the war was born the
      morning I began. There are at least two ways to see everything.
    </p>
    <p className={styles.para}>
      In wartime, you accept without questioning. It’s the only way to survive.
      From a very early age, I felt suffocated by secrets. There were no
      explanations, only stories.
    </p>
    <p className={styles.para}>
      My father was the richest man in our town, but I grew up believing I was
      the daughter of a ghost. From the time I was just a week old, I lived
      under my father’s tile roof. Five tin roofs separated me from the packed
      earth floor where my mother slept. As a young child, I would pass her in
      the street as if she were a stranger. Even though we were both well aware
      that her womb had been my first home, I was forbidden to speak to her.
      Feeling the throb of her blood in my veins, I dreaded becoming her.
    </p>
    <p className={styles.para}>
      In my first real memory of her, she is a wandering spirit, her white robes
      and long black hair floating in the salt wind off the sea. She passes our
      house on silent feet, a bowl of shadowed fruit balanced on the crown of
      her head, silhouetted against the round face of the full moon. I sit on
      the porch, huddled against the chimney for warmth, afraid to go inside,
      where my father’s anger submerges the rooms and sucks the air from my
      lungs. I watch as she drifts down to the sand, and when she is just a
      black emptiness against the sudden shimmer of breaking waves, I follow. I
      hear her murmur as the waves pull back. She seems to be speaking to
      someone, her palms pressed together in prayer, words escaping her chapped
      lips, flung away on the wind. I creep closer, holding my breath, waiting
      for the moon to open its suggestive mouth and answer. But her entreaties
      meet with silence from above, while the chaos of the waves swirls around
      her knees and soaks her thin tunic. She sinks into the wet sand, keening,
      and I stand off to the side, chilled, feeling nothing at all.
    </p>
    <p className={styles.para}>
      As I grew older, my mother terrified me in all her absurdity. Where others
      in our windswept town saw a liturgy, an appeal to the ancestors who
      inhabited the salty air all around us, I saw only celestial psychosis.
    </p>
    <p className={styles.para}>
      Sunyi, our housekeeper, had come with my father when he left my
      grandfather’s household to start his own family. One night, drifting high
      on too much rice wine from her secret stash, she told me my first story.
    </p>
    <p className={styles.para}>
      “Your parents were teenage lovers. You should have seen your mother then.
      A bamboo stick, thin and hollow, with a wild spirit trapped inside. Your
      father was bewitched. Just before his twenty-first birthday, he asked your
      grandfather to arrange the marriage. But your grandfather abhorred her
      restless spirit. A girl like that will never breed stability. There’s too
      much of the sea in her. I stood outside the door while your father waited
      for his father’s answer. Your grandfather stared at his bent head and
      folded hands for the time it takes to boil tea. He left the room without a
      word. The next day, we were ordered to give your father nothing but plain
      rice to eat. To teach his son the simple realities of life, he said. But I
      heard your father say that each grain reminded him of your mother, dressed
      all in white, feeding the whole town.”
    </p>
    <p className={styles.para}>
      My mother went around town proclaiming her sightings of gods and the
      messages they gave her. Even then, when she was just a girl, the
      townspeople looked to her for guidance. She helped them navigate the
      spirit world, and in return they called her shaman. She made up for her
      wild behavior with predictions sharp as arrows, hitting her targets with
      precision and truth. She could tell a mother when her daughter would
      marry. A son whether his father would recover from cancer. She could see
      fate, whether suffering or reward, and the townspeople trusted her to tell
      the truth without blinking. The world was small then, and people needed
      something immediate to believe in. They gave her their last pennies in
      exchange for the authentic vision of themselves they saw in her steady
      gaze. But my grandfather saw something different. He looked into her wide,
      bright eyes and saw illness and disgrace.
    </p>
    <p className={styles.para}>
      “Your father was always as stubborn and brutal as he is now. He ate almost
      nothing for weeks. Many thick foggy mornings, when I went to fetch water,
      I saw him sneaking back into the house through the window of his room. But
      his father’s judgment poisoned them, as happens to so many. One morning,
      when the sun had just painted a bloody line on the wall over the bed, he
      finally saw in her sleeping face everything his father had seen: an
      affliction where there had been a gift, frenzy mistaken for beauty. And
      it’s like that for all of us. Clouds scuttle across the night sky and it
      seems the stars themselves are moving. But once you see it’s the clouds
      flying and the stars are fixed, you can’t ever convince the stars to move
      again.”
    </p>
    <p className={styles.para}>
      As my mother cleaved to her visions, wound tighter in the shrouds of the
      spirits, my father began to unravel. He needed a wife who would nourish
      him. In his mind, the sea, the pine trees, and the moon were all
      competitors for her love. So he returned to my grandfather’s house and
      humbly declared his willingness to marry whomever his father chose for
      him. He rushed down the beaten path of an arranged marriage.
    </p>
    <p className={styles.para}>
      I have no idea whether my father and stepmother were ever happy. By the
      time I was old enough to weigh their love, whatever joy may once have lit
      their life together had been battered and dragged under by the waves of
      fate. Their first child, a girl, brought shame on the family in a world
      where only boys were valued. Only boys were worthy of education or
      inheritance. Only boys carried on the family name. A year later, another
      little girl was born. She lived a few fragile days, and died of a
      mysterious illness. No one mourned for her. She was not what was desired.
    </p>
    <p className={styles.para}>
      My grandfather blamed my stepmother. Not for his granddaughter’s death,
      which didn’t concern him, but for her failure to produce a son. He was a
      patient man. He waited ten years, but the boy he longed for never came.
      Finally, as was common in such cases in those days, he arranged a
      surrogate for my father. A single girl in the village, young and vital,
      who would provide the desired heir. For reasons of his own, he chose the
      village shaman.
    </p>
    <p className={styles.para}>
      I’ve often wondered what my father felt as my mother’s expanding belly
      rounded out her white tunic like a waxing moon. Hope, perhaps. The sense
      of having come full circle. If fate had given him a son, he might have
      lived a different life. He might have been a different man.
    </p>
    <p className={styles.para}>
      But fate gave my father another girl, born into the world through the
      tunnel of a fierce, inquisitive spirit. A girl with the shaman’s eyes.
      Resentment reached in and squeezed my father’s heart, and it never let him
      go.
    </p>
    <p className={styles.para}>
      Shortly before I was born, when war loomed like a tanker on the horizon,
      my father took several precautions to ensure a safe escape to Japan in
      case the worst should happen. He hired laborers to cut a steep staircase
      into the cliffs behind the house, hired fishermen to build a dock, and
      anchored a large boat in the cove. As my mother’s birth screams ended and
      my first cry filled the air, he watched with satisfaction as his builders
      bolted a thick metal door over a concrete bunker next to his house. From
      the time I came under his roof until the war ended, I slept there every
      night. Sunyi carried me down into the earth as the light faded from the
      sky. My infant dreams were weighted down by the heavy drone of American
      bombers. The bunker was heated by a single stove, and we slept around it
      like a cold spiral galaxy clustered around the sun. The warmest places
      next to the stove went to my father and stepmother. My older sister curled
      up nearby. The servants slept at a respectful distance, swaddled in rough
      wool blankets.
    </p>
    <p className={styles.para}>
      My stepmother’s distaste for me was never a secret. It could have been
      different. She had lost one of her own daughters, and here was another
      baby to fill her empty arms. But the wound in her heart had scabbed over,
      and she was not willing to tear it open for me. When I arrived at the
      house, there was no one to nurse me. Sunyi gave me rice milk and honey to
      drink and expected me to die within a few weeks. Another lost girl pressed
      into the earth under a tombstone of expectation and disappointment. On my
      first night down in the bunker, unwilling to coddle me herself, my
      stepmother sent me to sleep with the maids, far from the warmth of the
      stove.
    </p>
    <p className={styles.para}>
      In the morning, wakened by my cries, Sunyi was shocked to find my face
      frozen where my cheek had rested on the cold tile floor. She brought me to
      the stove, chaffed my face with her coarse blanket, and heated rice milk
      to give me. But my lips remained frozen, unable to suck from the bottle.
      Even when I screamed with the pain, my left eye stayed open, demanding an
      explanation from the world.
    </p>
    <p className={styles.para}>
      Reluctantly, my father sent for the town herbalist. As a remedy, the
      healer boiled medicinal herbs and seared them into my skin to bring it
      back to life. He pricked my cheeks with needles to block the nerve paths
      and prevent the damage from spreading. The feeling returned to my face,
      but the treatment left me with permanent nerve damage.
    </p>
    <p className={styles.para}>
      As long as I can remember, I’ve had a constant pain behind my left ear. I
      don’t hear well, smiling is nearly impossible, and my left eyelid doesn’t
      close all the way, leaving me hypersensitive to air and light. On that
      day, I became not merely a burden, an unwanted girl, the daughter of a
      crazy woman. I was damaged goods.
    </p>
    <p className={styles.para}>
      But I survived. I grew. My sister and I bore the brunt of my father’s
      disappointment. Miha was ten years old when I was born. Already she
      carried the scars of abuse, on her skin and in her heart. Whatever
      affection my stepmother may have felt for her daughter was no match for
      the shame my grandfather heaped over her. Miha needed me as much as I
      needed her. From the time I could walk, it was clear that she would be the
      only one to guide me.
    </p>
    <p className={styles.para}>
      I never saw my father cry. I imagine many children can say the same. But I
      never saw him laugh, either. The only familiar emotion was anger. In our
      house, I had no reflection. All the mirrors had been destroyed in fits of
      rage, shattered by flying furniture. The shards of glass ringing across
      the floor seemed to satisfy my father even as they prodded the rest of us
      to imagine what more he might be capable of. After one of his rages, when
      he’d scattered shredded furniture through the house like matchsticks, he
      would go to the attic with several packs of cigarettes and a chamber pot
      and wouldn’t come down for days. The smell of cigarette smoke leaking down
      through the floorboards was a comfort, an assurance that we were safe for
      the time being. After days without sustenance, he would emerge, serene,
      and Sunyi would go up to empty the chamber pot and sweep up the cigarette
      butts.
    </p>
    <p className={styles.para}>
      Outside of our house, he was a different man. The neighbors knew him to be
      generous and dignified. He always gave to anyone in need, even strangers.
      He never touched a drop of alcohol. He abhorred any public loss of
      control. Standing at the window after dinner, he would watch the
      fishermen, drunk after a day on the shivering sea, fighting and rolling in
      the dusty street. “Dogs,” he’d spit at the glass.
    </p>
    <p className={styles.para}>
      In those days, we lived at the edge of the world. Beyond our windows,
      nothing but waves upon waves separated us from Japan. Two roads converged
      at our front door, awash in sunshine, sea breezes, and refugees from the
      North. They slept by the side of the road, wrapped in blankets, waiting
      for their chance. The boats in the harbor ferried fish, passengers, and
      black market goods all bundled together in rotting holds. There was no
      lock on our door, and my father made a point of inviting anyone who was
      hungry to eat with us. As the largest landowner in town, his storerooms
      were full of food, and his maids fed the laborers, the farmers, and any
      hungry refugees who happened to be crowded onto our porch. Many of these
      women spent the night in my father’s room, exchanging what they could for
      his generosity to them and their children. They drifted through our house
      like ghosts, haunting the blank spaces between my father’s public persona
      and his private rage. Years later, as each new year broke over the old
      one, I would marvel at the crates of crisp apples, baskets of persimmons,
      and boxes of exotic pastries that arrived at our door to thank my father
      for his time-worn hospitality.
    </p>
    <p className={styles.para}>
      I was four years old and the listless refugees were long gone when I saw
      my first American soldier. All my life, they had colonized our
      imaginations and fleshed the specters of our dreams: stories of tall broad
      men with eyes like the sea and hair like standing wheat. One afternoon
      Sunyi came rushing into my room, flung all the linen from my futon cabinet
      onto the floor, and used her teeth and fingernails to tear my oldest sheet
      into long strips. I couldn’t catch my breath in time to ask what she was
      doing. The wailing from our neighbor’s house was my first clue. The woman
      had been shot as she was gathering mushrooms in the forest. As Sunyi wound
      the makeshift bandages tight around her shoulder and one of the other
      maids ran to fetch the healer, the women wailed out a strange word over
      and over again, like a mantra: “Yanqui! Yanqui!” I rained questions down
      on Sunyi, but she just pressed her lips together and wiped the peppery
      blood from her hands.
    </p>
    <p className={styles.para}>
      The next day, the tallest man I had ever seen arrived at the edge of town.
      The neighbor children clustered on the pine-dark hill overlooking the
      intersection in front of my father’s house. As the golden-haired giant
      drew nearer, I could see the sun glinting off a long black metal tube
      strapped across his chest.
    </p>
    <p className={styles.para}>
      “Yanqui! Yanqui!” shrieked the children on the ridge. Panic stricken, I
      lunged up from the porch and tore into the house, tripping over a rug and
      struggling back to my feet as the screams pushed louder through the walls.
      I rushed to my room and, as I’d seen Sunyi do, I tore the sheets and
      blankets from the futon cabinet and hid myself inside. I stayed there,
      listening to the sound of my own breath, while Korean whispers filtered
      through the dusty air and the sound of foreign words, bright and clear as
      temple bells, pierced my hiding place like bullets. My sister found me
      there. She coaxed me out, and together we crept on bare, silent feet to
      the porch. There, next to my father and Mr. Kim, one of the teachers at
      the school, the Yanqui sat cross-legged and slouching. His gun lay next to
      him on the ground. I gasped so loud the whole group turned to us, and the
      soldier grinned with all his teeth.
    </p>
    <p className={styles.para}>
      Mr. Kim nodded at the Yanqui’s words and stumbled through his own replies.
      My father waved an imperious hand to shush Sunyi and a few of the other
      women who were hissing quiet questions in Korean. The soldier reached into
      his backpack and produced a bag of something that clacked like the
      seashell anklet my mother wore when she danced through town. I drew back
      as he held it out to me. He looked into my eyes and said something,
      shaking the bag, but I clasped my hands behind my back and shook my head.
      My father squinted and made a sound low in his throat, and my knees
      started to quake. Mr. Kim let out something between a laugh and a sigh and
      reached for the bag, ripping off a corner and pouring the contents into
      his palm. Little round jewels emerged in bright red, green, and yellow,
      along with others the color of mud. The soldier reached for Mr. Kim’s hand
      and popped one of these gems into his mouth, crunching away behind his big
      white teeth. Mr. Kim took one himself, smiling and nodding at the blond
      giant. My sister, braver than I was, took an emerald pebble from her
      teacher’s palm and popped it into her mouth. Then Mr. Kim held his hand
      out to me. My father prodded me with one long finger. The candy tasted
      like sugar and butter and sand, and it crunched like raw peas. I wrinkled
      my nose, and the ocean-eyed soldier laughed.
    </p>
    <p className={styles.para}>
      Mr. Kim explained to us later that the man was the leader of the Yanqui
      soldiers, that one of his men had been hunting in the forest above the
      town and had mistaken our neighbor for a deer. The American had explained
      this mistake as though it was obvious, since the differences between us
      were so great. They seemed to think we looked like forest fauna. The
      soldier brought the M&amp;Ms as a peace offering. To me, they looked like
      another kind of weapon. Bullets dressed in beautiful colors.
    </p>
    <p className={styles.para}>
      From then on, soldiers appeared frequently in our small town. News of
      their presence always carried on the wind in their ringing words and their
      machine-gun laughter. The other children learned to anticipate the candy
      they always received from their big rough hands, hands like those of the
      farmers on my father’s land. Even my sister learned to like their sugary
      treats. But I rushed to hide whenever I heard them. It was as if I could
      already see my future coming down the street after them, the barrel of its
      gun snaking between their broad shoulders, aimed squarely at my heart.
    </p>
  </div>
)
