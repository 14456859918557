import React, { useState, useEffect } from "react"
import styles from "./indicator.module.css"

const Indicator = () => {
  const [position, setPosition] = useState(0)

  useEffect(() => {
    const listenToScroll = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop

      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight

      const scrolled = winScroll / height

      setPosition(scrolled)
    }

    window.addEventListener("scroll", listenToScroll)

    const cleanup = () => {
      window.removeEventListener("scroll", listenToScroll)
    }
    return cleanup
  })

  return (
    <div className={styles.wrapper}>
      <div className={styles.bar} style={{ width: `${position * 100}%` }} />
    </div>
  )
}

export default Indicator
