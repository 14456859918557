import React from "react"
import sig_roger from "../../assets/sig_roger_tmp.png"
import styles from "./foreword.module.css"

export default () => (
  <div className={styles.foreword}>
    <h2 className={styles.title}>Foreword</h2>
    <p>Sunna Bohlen is a remarkable woman.</p>
    <p className={styles.para}>
      I should know – we have been friends for more than 40 years. Her creative
      energy has always impressed me. Over her long career, Sunna has
      distinguished herself in the visual arts and in poetry, but she also
      excels in the culinary arts. I have been fortunate to share many meals
      with her.
    </p>
    <p className={styles.para}>
      Sunna’s painting and poetry is infused with a strong ener- getic force,
      reflecting serenity and pathos, as does her life. She is a courageous
      adventurer, a fearless spirit, using occasional problems as
      stepping-stones along the path to her destination. She lives and loves
      passionately. But what I most admire in Sunna is her resilience, her
      ability to recover from life’s unfortunate turmoil.
    </p>
    <p className={styles.para}>
      Now this unique survivor has written a novel, sharing the motivations and
      inspirations for her art, her resilience, and her life. Her book will
      touch your life, and you will never be the same.
    </p>
    <div className={styles.sig_wrapper}>
      <img
        className={styles.sig_img}
        src={sig_roger}
        alt="Roger Guillemin Signature"
      />
    </div>
    <p className={styles.guillemin}>
      Roger Guillemin is a French-born, American neuroscientist.
      <br />
      He was awarded the Nobel Prize in Medicine in 1977.
    </p>
  </div>
)
